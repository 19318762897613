import Gumshoe from 'gumshoejs';

export default (() => {
    const navbar = document.querySelector('#float-navbar');
    window.addEventListener('load', () => {
        const infonavbar = document.querySelector('#info-navbar');
        if (infonavbar) {
            new Gumshoe('#info-navbar a', {
                offset: 120,
            });
        }
    });
})();
