export default (() => {
  window.addEventListener('load', () => {
    /* Генерация иконок загруженных изображений */
    const fileInput = document.getElementById('fileInput');
    const imgList = document.getElementById('imgList');
    if (fileInput && imgList) {
      fileInput.addEventListener(
        'change',
        function (evt) {
          imgList.innerHTML = '';
          let files = evt.target.files; // Список изображений
          imgList.classList.add('py-4');

          if (files.length === 0) {
            imgList.classList.remove('py-4');
          }

          for (let i = 0, f; (f = files[i]); i++) {
            let reader = new FileReader();
            // Обработка изображений
            reader.onload = (function (theFile) {
              return function (e) {
                let img = new Image();
                img.classList.add('w-10', 'mr-3', 'rounded');
                img.title = theFile.name;
                img.src = e.target.result;
                imgList.insertBefore(img, null);
              };
            })(f);

            reader.readAsDataURL(f);
          }
        },
        false
      );
    }
  });
})();
