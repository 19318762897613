import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'

Alpine.plugin(collapse);
Alpine.plugin(focus);

Alpine.store('mobileNav', {
    position: false,

    togglePosition() {
        this.position = !this.position;

        this.position
            ? document.body.classList.add('overflow-y-hidden')
            : document.body.classList.remove('overflow-y-hidden')
    }
});

Alpine.store('search', {
    showInHeader: false,
    showInNav: false,
    showInCatalog: false,

    setPositionInHeader(v) {
        this.showInHeader = v;
    },

    setPositionInNav(v) {
        this.showInNav = v;
    },

    setPositionInCatalog(v) {
        this.showInCatalog = v;
    }
});

Alpine.store('mobileCatalog', {
    position: false,

    togglePosition() {
        this.position = !this.position;
    }
});

window.Alpine = Alpine;
Alpine.start();
