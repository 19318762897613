import { Fancybox } from '@fancyapps/ui';

const ua = {
    CLOSE: 'Закрити',
    NEXT: 'Наступна',
    PREV: 'Попередня',
    MODAL: 'Ви можете закрити модальне вікно за допомогою ESC',
    ERROR: 'Щось пійшло не так, спробуйте пізніше',
    IMAGE_ERROR: 'Зображення не знайдене',
    ELEMENT_NOT_FOUND: 'HTML Element не знайдено',
    AJAX_NOT_FOUND: 'Помилка завантаження AJAX : Не знайдено',
    AJAX_FORBIDDEN: 'Помилка завантаження AJAX : Заборонено',
    IFRAME_ERROR: 'Помилка завантаження сторінки',
};

Fancybox.defaults.l10n = ua;

Fancybox.bind('[data-fancybox]', {
    Thumbs: false,
    Image: {
        zoom: false,
        click: false,
        wheel: 'slide',
    },
    infinite: false,
});

export { Fancybox };
