const Log = require("laravel-mix/src/Log");

document.addEventListener('DOMContentLoaded', () => {
    const cart_counter = document.querySelector('#cart_count');
    const cart_counter_model = document.querySelector('#cart_count_model');
    const m_cart_counter = document.querySelector('#mobile_cart_count');
    const m_cart_counter_header = document.querySelector('#mobile_cart_count_header');
    const cartTooltip = document.querySelector('.js-cart-tooltip')
    const cartTooltipCloseBtn = cartTooltip ? cartTooltip.querySelector('.js-close-tooltip') : null;
    const cartMobileComponent = document.querySelector('.js-cart-mobile')
    const closeCartMobileComponentBtns = document.querySelectorAll('.js-cart-mobile-close')
    const showCartMobileComponentBtns = document.querySelectorAll('.js-cart-mobile-show')
    const windowWidth = window.innerWidth
    const windowsMobile = 1024;
    const lang = document.querySelector('html').getAttribute('lang')
    let focusInputMobile = true;
    function getHeaders() {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        };
    }

    function updateCounter(counter, count) {
        if (!counter) {
            return
        }

        counter.textContent = count;
        if (count > 0) {
            counter.style.display = null;
        } else {
            counter.style.display = 'none';
        }
    }

    document.querySelectorAll('.buy-button').forEach(function (buyButton) {
        buyButton.addEventListener('click', function () {
            addToCart(this.value);

            dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                    items: [
                        {
                            item_name: this.dataset.title,
                            item_id: this.dataset.id,
                            price: parseFloat(this.dataset.price).toFixed(2),
                            item_brand: this.dataset.brand,
                            item_category: this.dataset.category,
                        },
                    ],
                },
            });

            fbq('track', 'AddToCart', {
                value: this.dataset.price,
                currency: 'UAH',
                content_type: 'product',
                content_ids: [this.dataset.id],
            });
        });
    });

    function updateCart() {
        // document
        //     .getElementById('close-mobile-cart')
        //     .addEventListener('click', hideMobileCart);

        document.querySelectorAll('.clear_cart').forEach(function (button) {
            button.addEventListener('click', function () {
                const headers = getHeaders();
                fetch('/cart/drop', {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify({ lang: lang })
                })
                    .then((response) => response.json())
                    .then((result) => {
                        updateViewCart(result.view);
                        updateCounter(cart_counter, result.count);
                        updateCounter(cart_counter_model, result.count);
                        updateCounter(m_cart_counter, result.count);
                        updateCounter(m_cart_counter_header, result.count);
                        updateCart();
                        continueShopping()
                        selectInputMobile()
                    });
            });
        });

        let productDelete = document.querySelectorAll('.product-delete');
        if (productDelete) {
            productDelete.forEach((button) => {
                button.addEventListener('click', () => {
                    const headers = getHeaders();
                    const product = button.value;
                    fetch('/cart/remove', {
                        headers: headers,
                        method: 'POST',
                        body: JSON.stringify({ product: product, lang: lang }),
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            updateCounter(cart_counter, result.count);
                            updateCounter(cart_counter_model, result.count);
                            updateCounter(m_cart_counter, result.count);
                            updateCounter(m_cart_counter_header, result.count);
                            updateViewCart(result.view);
                            updateCart();
                            continueShopping()
                            selectInputMobile()
                        });
                });
            });
        }

        function changeProduct(product, minus = false, count = null) {
            const headers = getHeaders();
            let data = { product: product, lang: lang };
            if (minus) {
                data.minus = minus;
            }
            if (count) {
                data.count = count
            }
            fetch('/cart/change', {
                headers: headers,
                method: 'POST',
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (!result.status) {
                        if (result.reload) {
                            updateScroll();
                            return document.location.reload();
                        }
                        return alert(result.message);
                    }

                    updateCounter(cart_counter, result.count);
                    updateCounter(cart_counter_model, result.count);
                    updateCounter(m_cart_counter, result.count);
                    updateCounter(m_cart_counter_header, result.count);
                    updateViewCart(result.view);
                    updateCart();
                    continueShopping()
                    selectInputMobile()
                    if(windowWidth < windowsMobile) {
                        document.body.classList.add('overflow-hidden')
                        if (count) {
                            if(focusInputMobile) {
                                focusMobile(product)
                            }
                            handleSwipe(product, null, null)
                        }
                    }
                });
                const focusMobile = (product) => {
                    const mobile = document.querySelector('#mobile-scroll');
                    const element = mobile.querySelector(`#product-count-${product}`);
                    const value = element.value;
                    element.type = 'text';
                    element.focus();
                    if (value.length > 0) {
                        element.setSelectionRange(value.length, value.length);
                    }
                    element.type = 'number';
                }

        }

        let minusCount = document.querySelectorAll('.product-minus-count');
        if (minusCount) {
            minusCount.forEach((button) => {
                const product = button.value;
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    changeProduct(product, true);
                });
            });
        }

        let plusContent = document.querySelectorAll('.product-plus-count');
        if (plusContent) {
            plusContent.forEach((button) => {
                const product = button.value;
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    changeProduct(product);
                });
            });
        }
        const countIntputs = document.querySelectorAll('.count');
        if (countIntputs) {
            let lastValue = null;
            const handleInput = (e, isMobile) => {
                const countIntput = e.currentTarget;
                countIntput.classList.add('active');
                const product = countIntput.getAttribute('data-id');
                const regex = /^[1-9]\d*$/;
                const value = countIntput.value;
                lastValue = regex.test(value) ? value : 1;
                if(isMobile) {
                    setTimeout(() => {
                        changeProduct(product, false, lastValue);
                    }, 1100);

                   return handleSwipe(product, null , true)
                }
                else {
                    setTimeout(() => {
                        changeProduct(product, false, lastValue);
                    }, 500);
                }
            };

            const focusHidden = (e, isMobile) => {
                if (isMobile && e.key === 'Enter') {
                    return focusInputMobile = false;
                }
            }

            countIntputs.forEach(countIntput => {
                if (countIntput.classList.contains('mobile')) {
                    countIntput.addEventListener('keydown', (e) => focusHidden(e, true));
                    countIntput.addEventListener('keyup', (e) => handleInput(e, true));
                } else {
                    countIntput.addEventListener('keyup', (e) => handleInput(e, false));
                }
            });
        }
    }

    function addToCart(product) {
        const headers = getHeaders();
        fetch('/cart/add', {
            headers: headers,
            method: 'POST',
            body: JSON.stringify({ product: product, lang: lang}),
        })
            .then((response) => response.json())
            .then((result) => {
                if (!result.status) {
                    if (!result.status) {
                        if (result.reload) {
                            updateScroll();
                            return document.location.reload();
                        }
                        return alert(result.message);
                    }
                }

                showCartTooltip()

                updateViewCart(result.view);
                updateCart();
                selectInputMobile()
                continueShopping()
                updateCounter(cart_counter_model, result['count']);
                updateCounter(cart_counter, result['count']);
                updateCounter(m_cart_counter, result['count']);
                updateCounter(m_cart_counter_header, result['count']);
                const cartSum = document.querySelector('#product-cart-sum');
                const cartCount = document.querySelector('#product-cart-count');
                const carts = document.querySelectorAll('.cart');
                const cartModel = document.querySelector('.cart-moded__box');
                if(windowWidth > windowsMobile) {
                    fadeIn(cartModel, 'flex');
                    document.body.classList.add('overflow-hidden')
                }

                // openMobileCart();

                if (cartCount && cartSum) {
                    fetch('/cart/product-view', {
                        headers: headers,
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            cartSum.textContent = result.sum;
                            cartCount.textContent = result.count;
                        });
                }
            });
    }

    function updateViewCart(views) {
        const scrollPositions = {
            mobile: getScrollTop('mobile-scroll'),
            cartModel: getScrollTop('cart-model-item'),
            cart: getScrollTop('product-cart')
        };
        for (const [key, value] of Object.entries(views)) {
            const el = document.getElementById(key)
            if (!el) {
                return
            }
            if (key === 'mobile-cart') {
                 el.innerHTML = value;
            }
            else {
                const parentEl = el.parentElement;
                const lastChild = parentEl.lastElementChild;
                if (lastChild) {
                    lastChild.remove();
                }
                parentEl.insertAdjacentHTML('beforeend', value)
            }
        }
        requestAnimationFrame(() => {
            setScrollTop('mobile-scroll', scrollPositions.mobile);
            setScrollTop('cart-model-item', scrollPositions.cartModel);
            setScrollTop('product-cart', scrollPositions.cart);
        });


    }

    function getScrollTop(elementId) {
        const element = document.getElementById(elementId);
        return element ? element.scrollTop : 0;
    }

    function setScrollTop(elementId, scrollTop) {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollTop = scrollTop;
        }
    }

    function handleSwipe(product = null, input = null, action = null) {
        let scroll = document.querySelector('#mobile-scroll');
        let element = input ?? scroll.querySelector(`#product-count-${product}`);
        let touchStartX = 0;
        let touchEndX = 0;
        scroll.addEventListener('touchstart', (e) => {
            touchStartX = e.changedTouches[0].screenX;
        });
        scroll.addEventListener('touchend', (e) => {
            touchEndX = e.changedTouches[0].screenX;
            handleSwipeDirection();
        });

        scroll.addEventListener('click', (e) => {
            if (element && !element.contains(e.target)) {
                focusInputMobile = false;
            }
        });


        const handleSwipeDirection = () => {
            if (touchEndX < touchStartX) {
                if(action) {
                    return focusInputMobile = false;
                }
                element.blur();
            }
            if (touchEndX > touchStartX) {
                if(action) {
                   return  focusInputMobile = false;
                }
                element.blur();
            }
            return focusInputMobile = true;

        };
    }

    function openMobileCart() {
        document.body.classList.add('overflow-hidden')

        cartMobileComponent.classList.add('transform')
        cartMobileComponent.classList.add('translate-x-full')
    }

    function hideMobileCart() {
        cartMobileComponent.classList.remove('transform')
        cartMobileComponent.classList.remove('translate-x-full')
        document.body.classList.remove('overflow-hidden')
    }


    let isTooltipVisible = false;

    function showCartTooltip() {
        if (!isTooltipVisible) {
            isTooltipVisible = true;
            cartTooltip.classList.remove('-top-16');
            cartTooltip.classList.add('top-16');

            setTimeout(() => {
                hideCartTooltip();
            }, 3000);
        }
    }



    function fadeIn(el, display){
        el.style.opacity = 0;
        el.style.display = display || "block";

        (function fade() {
          var val = parseFloat(el.style.opacity);
          if (!((val += .1) > 1)) {
            el.style.opacity = val;
            requestAnimationFrame(fade);
          }
        })();
      };

    function fadeOut(el){
        el.style.opacity = 1;
        (function fade() {
            if ((el.style.opacity -= .1) < 0) {
                el.style.display = "none";
            } else {
                requestAnimationFrame(fade);
            }
        })();
    }


    function hideCartTooltip() {
        isTooltipVisible = false
        cartTooltip.classList.add('-top-16')
        cartTooltip.classList.remove('top-16')
    }

    function selectInputMobile() {
        const mobileBasketInputs =  document.querySelectorAll('#mobile-cart [id^="product-count-"]');
        mobileBasketInputs.forEach(input => {
            input.addEventListener('click', ()=> {
                input.select();
                document.body.classList.add('overflow-hidden')
                handleSwipe(null, input, null)
            })
            input.addEventListener('keypress', (event) => {
                if (event.key === 'Enter' || event.key === 'Return') {
                    input.blur();
                    document.body.classList.add('overflow-hidden')
                }
            });
        })
    }

    function continueShopping() {
        const btnClose = document.querySelector('.continue_shopping');
        if(btnClose) {
            btnClose.addEventListener('click', ()=> {
                const cartModel = document.querySelector('.cart-moded__box');
                fadeOut(cartModel)
                document.body.classList.remove('overflow-hidden')
            })
        }
    }
    if(cartTooltipCloseBtn) {
        cartTooltipCloseBtn.addEventListener('click', () => {
            hideCartTooltip()
            openMobileCart()
        })
    }

    showCartMobileComponentBtns.forEach((el) => {
        el.addEventListener('click', openMobileCart)
    })
    closeCartMobileComponentBtns.forEach((el) => {
        el.addEventListener('click', hideMobileCart)
    })
    updateCart();
    selectInputMobile()
    continueShopping()

    function updateScroll() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
})
