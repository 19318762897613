function getHeaders() {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    };
}

function updateCounter(counter, count) {
    counter.textContent = count;
    if (count > 0) {
        counter.style.display = null;
    } else {
        counter.style.display = 'none';
    }
}

function showSuccessModal(type) {
    const successModal = document.querySelector('#'+type+'-success-modal')
    
    successModal.classList.remove('-top-16')
    successModal.classList.add('top-16')
    successModal.classList.add('lg:top-8')

    setTimeout(() => {
        closeSuccessModal(type)
    }, 2000)
}

function closeSuccessModal(type) {
    const successModal = document.querySelector('#'+type+'-success-modal')
    
    successModal.classList.add('-top-16')
    successModal.classList.remove('top-16')
    successModal.classList.remove('lg:top-8')
}

const compare_counter = document.querySelector("#compare_counter");
const compare_categories = document.querySelector('#compare_categories');

const countFavorites = document.querySelector('#favorite_count');

const difference = document.querySelector('#compare-with-differences');
if (difference) {
    difference.addEventListener('change', function () {
        const url = new URL(location.href);
        if (this.checked) {
            url.searchParams.append('show', 'difference');
        } else {
            url.searchParams.delete('show');
        }
        location.href = url.toString();
    });
}


let waitingButton = document.querySelector('#waiting-button');
if (waitingButton) {
    waitingButton.addEventListener('click', function () {
        const headers = getHeaders();
        fetch('/waiting', {
            headers: headers,
            method: "POST",
            body: JSON.stringify({product: this.value})
        }).then(response => response.json())
            .then(() => {
                alert('Продукт добавлен в ожидаемое');
            }).catch(() => {
            alert('Авторизируйтесь');
        });
    });
}

document.querySelectorAll('.delete-waiting').forEach((button) =>
    button.addEventListener('click', function () {
        const headers = getHeaders();
        fetch('/waiting/remove', {
            headers: headers,
            method: "POST",
            body: JSON.stringify({product: this.value})
        }).then(() => {
            location.reload();
        });
    }));

document.querySelectorAll('.compare-category-drop').forEach(function (deleteCategoryButton) {
    deleteCategoryButton.addEventListener('click', function () {
        const category = this.value;
        deleteCategory(category);
    });
});


document.querySelectorAll('.favorite-button').forEach(function (favouriteButton) {
    favouriteButton.addEventListener('click', function () {
        addToFavorites(this.value);
    })
});

document.querySelectorAll('.favorite-remove').forEach(function (removeButton) {
    removeButton.addEventListener('click', function () {
        removeFromFavorite(this.value);
    })
});

document.querySelectorAll('.button-compare').forEach(function (compareButton) {
    compareButton.addEventListener('click', function (event) {
        addProductToCompare(this.value);
    });
});

document.querySelectorAll('.button-delete-compare').forEach(function (deleteButton) {
    deleteButton.addEventListener('click', function () {
        deleteProductFromCompare(this.value);
    });
});


function addProductToCompare(product_id) {
    const headers = getHeaders();
    fetch('/compare', {
        headers: headers,
        method: "POST",
        body: JSON.stringify({product: product_id})
    }).then(response => response.json())
        .then((result) => {
            updateCounter(compare_counter, result['count']);
            compare_categories.innerHTML = result['categories_html'];
            showSuccessModal('compare')
        });
}

function removeFromFavorite(product_id) {
    const headers = getHeaders();
    fetch('/favorites/remove', {
        headers: headers,
        method: "POST",
        body: JSON.stringify({product: product_id})
    }).then(response => response.json())
        .then((result) => {
            updateCounter(countFavorites, result.count);
            location.reload();
        });
}

function deleteProductFromCompare(product_id) {
    const headers = getHeaders();
    fetch('/compare-delete', {
        headers: headers,
        method: "POST",
        body: JSON.stringify({product: product_id})
    }).then(response => response.json())
        .then((result) => {
            let count = result['count'];
            updateCounter(compare_counter, count);
            location.reload();
        });
}

function deleteCategory(category) {
    const headers = getHeaders();
    fetch('/compare/drop-category', {
        headers: headers,
        method: "POST",
        body: JSON.stringify({category: category})
    }).catch((error) => console.log(error))
        .then(() => location.reload());
}

function addToFavorites(product) {
    const headers = getHeaders();
    fetch('/favorites/add', {
        headers: headers,
        method: "POST",
        body: JSON.stringify({product: product})
    }).then(response => response.json())
        .then(response => {
            const count = response.count;
            updateCounter(countFavorites, count);
            showSuccessModal('favourites')
        })
}

let phonesFields = document.querySelectorAll('[name="telephone"], [name="phone"], [type="tel"]');

if (phonesFields.length) {
    phonesFields.forEach(phoneField => {
        phoneField.addEventListener('input', function (e) {
            var x = e.target.value.replace(/\+\D/g, '')
                .match(/(\+)?(\d{0,1})(\d{0,1})(\d{0,1})(\d{0,1})(\d{0,11})/);

            if (`${x[2]}${x[3]}${x[4]}${x[5]}` === '3800') {
                e.target.value = `+${x[2]}${x[3]}${x[4]}`;

                return;
            }

            if (x[1] === '+') {
                return;
            }

            e.target.value = `+${x[0]}`;
        });
    });
}
