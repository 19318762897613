window.productModificationCards = () => ({
    count: 1,
    up() {
        this.count += 1;
    },
    down() {
        if(this.count > 1) {
            this.count -= 1;
        }
    }
});
