export default (() => {
    window.addEventListener('load', () => {
        /* Плавная прокрутка */
        const linkNav = document.querySelectorAll('[href^="#"]'), //выбираем все ссылки к якорю на странице
            V = 0.2; // чем меньше значение - тем больше скорость
        for (let i = 0; i < linkNav.length; i++) {
            linkNav[i].addEventListener(
                'click',
                function (e) {
                    e.preventDefault();
                    let w = window.pageYOffset, // производим прокрутку
                        hash = this.href.replace(/[^#]*(.*)/, '$1'); // к id элемента, к которому нужно перейти
                    let t = document.querySelector(hash).getBoundingClientRect()
                            .top, // отступ от окна браузера до id
                        start = null;
                    requestAnimationFrame(step);

                    function step(time) {
                        if (start === null) start = time;
                        let progress = time - start,
                            r =
                                t < 0
                                    ? Math.max(w - progress / V, w + t)
                                    : Math.min(w + progress / V, w + t);
                        window.scrollTo(0, r - 80);
                        if (r != w + t) {
                            requestAnimationFrame(step);
                        }
                    }
                },
                false
            );
        }
    });
})();
